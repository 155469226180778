<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="blog"
              backText="blog"
              :title="`${form.id ? 'update' : 'add a'} Blog`"
              sub-title="write down the event"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <v-container>
      <main class="pb-9 pt-4">
        <div>
          <list title="basic content" class="h-100 wa__large">
            <loading v-if="loadingGetData" class="loading-data" />
            <v-form
              ref="blogForm"
              @submit.prevent="addBlog"
              :class="{ 'loading-form': loadingGetData }"
            >
              <hr class="mt-0" />
              <v-row>
                <v-col cols="12" sm="6" class="mt-1">
                  <v-text-field
                    label="title"
                    color="SonicSilver"
                    class="mt-0 pt-0"
                    dense
                    outlined
                    :rules="requiredRules"
                    v-model="form.title"
                  ></v-text-field>
                  <v-textarea
                    color="SonicSilver"
                    rows="4"
                    label="summary"
                    dense
                    outlined
                    :rules="requiredRules"
                    v-model="form.summary"
                  ></v-textarea>
                  <v-textarea
                    color="SonicSilver"
                    rows="3"
                    label="META TAG DESCRIPTION"
                    dense
                    outlined
                    v-model="form.meta_description"
                  ></v-textarea>
                  <AutocompleteModel
                    v-if="!loadingGetData"
                    label="tag models"
                    color="SonicSilver"
                    v-model="form.users"
                  />
                </v-col>
                <v-col cols="12" sm="6" class="pt-0">
                  <div class="h-100">
                    <div class="add__program w-100">
                      <image-cropper
                        :stencilProps="{
                          aspectRatio: 1280 / 720,
                          checkImageOrigin: false,
                        }"
                        :url.sync="form.thumbnail"
                        v-model="fileForm"
                        label="blog banner"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" v-if="showEditor">
                  <div class="blog__text__lable">blog text</div>
                  <editor :init="init" v-model="form.text"></editor>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-combobox
                    clearable
                    :append-icon="null"
                    hide-selected
                    label="tags"
                    outlined
                    color="SonicSilver"
                    dense
                    multiple
                    persistent-hint
                    small-chips
                    v-model="form.tags"
                  >
                    <template #selection="{ item }">
                      <v-chip
                        close
                        @click:close="removeTags(item)"
                        class="mt-1 chip__tag"
                        text-color="black"
                        label
                        small
                      >
                        {{ item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pb-10">
                <v-col cols="12" sm="6" class="pt-1 text-center text-sm-left">
                  <router-link
                    :to="{
                      name: 'blog',
                      params: { locale: $_getlocaleParam() },
                    }"
                    class="text-decoration-none"
                  >
                    <block-button
                      height="30"
                      class="btn__size__14 cancel__button br__mode"
                      text="cancel"
                      color="black"
                    ></block-button>
                  </router-link>
                </v-col>
                <v-col cols="12" sm="6" class="text-right pt-1">
                  <block-button
                    :text="form.id ? 'update' : 'save'"
                    height="30"
                    class="btn__size__18 site__button width-200 add__mode float-right"
                    :loading="loadingSendData"
                    type="submit"
                  ></block-button
                ></v-col>
              </v-row>
            </v-form>
          </list>
        </div>
      </main>
    </v-container>
    <footer>
      <div class="wa__home--footer">
        <site-footer :text="$_t('footerText')"></site-footer>
      </div>
    </footer>
  </div>
</template>
<script>
import {requiredRules} from "@/mixins/validate"
import AutocompleteModel from "@/components/Global/Input/AutocompleteModel.vue";
import ImageCropper from "@/components/Global/Input/ImageCropper";
import { mapActions } from "vuex";
import Editor from "@tinymce/tinymce-vue";
import { makeid } from "@/utils/math";
import { initTiny } from "@/plugins/tinymce/tinymce";
import BlogRepository from "@/abstraction/repository/blog/blogRepository";
const blogRepository = new BlogRepository();
import FileRepository from "@/abstraction/repository/FileRepository";
const fileRepository = new FileRepository();
const RANDOM_TOKEN = makeid(50);
var formDefault = {
  tinymce_batch_id: RANDOM_TOKEN,
};
export default {
  name: "add_blog",
  components: {
    ImageCropper,
    AutocompleteModel,
    Editor,
  },
  data() {
    return {
      init: initTiny(formDefault.tinymce_batch_id, "blog"),
      showEditor: false,
      form: {
        file_batch_id: RANDOM_TOKEN,
        users: [],
        thumbnail: "https://via.placeholder.com/1280x720",
      },
      fileForm: {
        model_name: "blog",
        collection_name: "main_image",
        batch_id: RANDOM_TOKEN,
      },
      loadingSendData: false,
      blogId: this.$route.params.id,
      loadingGetData: false,
    };
  },
  methods: {
    ...mapActions("blog", ["updateBlog", "storeBlog"]),
    removeTags(item) {
      this.form.tags.splice(this.form.tags.indexOf(item), 1);
      this.form.tags = [...this.form.tags];
    },
    async addBlog() {
      try {
        this.loadingSendData = true;

        if (!this.$refs.blogForm.validate()) {
          return;
        }

        if (this.fileForm.file) {
          await fileRepository.store(this.fileForm);
        }

        let response;
        if (this.form.id) {
          response = await this.updateBlog(this.form);
        } else {
          response = await this.storeBlog(this.form);
        }
        if (!(response instanceof Error)) {
          this.$router.push({
            name: "blog",
            params: {
              locale: this.$_getlocaleParam(),
            },
          });
        }
      } catch (e) {
        return e;
      } finally {
        this.loadingSendData = false;
      }
    },
    async loadBlogById() {
      if (this.blogId) {
        try {
          this.loadingGetData = true;
          const response = await blogRepository.show(this.blogId);
          this.form = { ...this.form, ...response };
          this.form.users = this.form.users_ids;
        } catch (error) {
          console.error(error);
          return error;
        } finally {
          this.loadingGetData = false;
        }
      }
    },
  },
  computed: {
    requiredRules,
  },
  created() {
    this.loadBlogById();
  },
  mounted() {
    this.showEditor = true;
  },
};
</script>
<style scoped>
/* container */
.container {
  max-width: 1640px;
}
.blog__text__lable {
  font-family: "Montserrat-light" !important;
  font-size: 14px !important;
  color: #acacac !important;
  font-weight: 100;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}
